import toast from "react-hot-toast";
import { Clear } from "@material-ui/icons";
import { cn } from "@/common/utils/css.utils";

const SuccessIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <path
      d="M19.626 11.72L13.906 17.4534L11.706 15.2534C11.5865 15.1138 11.4394 15.0004 11.274 14.9204C11.1086 14.8404 10.9284 14.7954 10.7448 14.7883C10.5611 14.7812 10.378 14.8121 10.2069 14.8792C10.0358 14.9462 9.88043 15.0479 9.75049 15.1778C9.62055 15.3078 9.51887 15.4632 9.45184 15.6343C9.38481 15.8054 9.35386 15.9885 9.36096 16.1721C9.36805 16.3557 9.41302 16.5359 9.49305 16.7013C9.57309 16.8667 9.68645 17.0138 9.82602 17.1334L12.9594 20.28C13.0839 20.4036 13.2317 20.5014 13.3941 20.5677C13.5566 20.6341 13.7305 20.6677 13.906 20.6667C14.2558 20.6652 14.591 20.5263 14.8394 20.28L21.506 13.6134C21.631 13.4894 21.7302 13.3419 21.7979 13.1795C21.8656 13.017 21.9004 12.8427 21.9004 12.6667C21.9004 12.4907 21.8656 12.3164 21.7979 12.1539C21.7302 11.9914 21.631 11.844 21.506 11.72C21.2562 11.4717 20.9183 11.3323 20.566 11.3323C20.2138 11.3323 19.8758 11.4717 19.626 11.72ZM15.9994 2.66669C13.3623 2.66669 10.7844 3.44867 8.59175 4.91376C6.3991 6.37884 4.69013 8.46123 3.68096 10.8976C2.67179 13.3339 2.40775 16.0148 2.92222 18.6012C3.43669 21.1876 4.70656 23.5634 6.57126 25.4281C8.43597 27.2928 10.8117 28.5627 13.3982 29.0772C15.9846 29.5916 18.6655 29.3276 21.1018 28.3184C23.5382 27.3092 25.6205 25.6003 27.0856 23.4076C28.5507 21.215 29.3327 18.6371 29.3327 16C29.3327 14.2491 28.9878 12.5152 28.3177 10.8976C27.6477 9.2799 26.6656 7.81004 25.4274 6.57193C24.1893 5.33382 22.7195 4.35169 21.1018 3.68163C19.4841 3.01156 17.7503 2.66669 15.9994 2.66669ZM15.9994 26.6667C13.8897 26.6667 11.8274 26.0411 10.0733 24.869C8.31915 23.697 6.95198 22.0311 6.14464 20.082C5.33731 18.1329 5.12607 15.9882 5.53765 13.9191C5.94922 11.8499 6.96512 9.94931 8.45688 8.45755C9.94864 6.96579 11.8493 5.94989 13.9184 5.53831C15.9875 5.12674 18.1322 5.33797 20.0813 6.14531C22.0304 6.95264 23.6963 8.31981 24.8684 10.0739C26.0404 11.8281 26.666 13.8904 26.666 16C26.666 18.829 25.5422 21.5421 23.5418 23.5425C21.5414 25.5429 18.8283 26.6667 15.9994 26.6667Z"
      fill="currentColor"
    />
  </svg>
);

const ErrorIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="M15.9994 2.66667C13.3623 2.66667 10.7844 3.44866 8.59175 4.91374C6.3991 6.37883 4.69013 8.46121 3.68096 10.8976C2.67179 13.3339 2.40775 16.0148 2.92222 18.6012C3.43669 21.1876 4.70656 23.5634 6.57126 25.4281C8.43597 27.2928 10.8117 28.5627 13.3982 29.0771C15.9846 29.5916 18.6655 29.3276 21.1018 28.3184C23.5382 27.3092 25.6205 25.6003 27.0856 23.4076C28.5507 21.215 29.3327 18.6371 29.3327 16C29.3327 14.249 28.9878 12.5152 28.3177 10.8976C27.6477 9.27988 26.6656 7.81003 25.4274 6.57191C24.1893 5.3338 22.7195 4.35167 21.1018 3.68161C19.4841 3.01155 17.7503 2.66667 15.9994 2.66667ZM15.9994 26.6667C13.1704 26.6667 10.4573 25.5429 8.45688 23.5425C6.45649 21.5421 5.33269 18.829 5.33269 16C5.32974 13.6311 6.12338 11.3301 7.58602 9.46667L22.5327 24.4133C20.6693 25.876 18.3682 26.6696 15.9994 26.6667ZM24.4127 22.5333L9.46602 7.58667C11.3294 6.12403 13.6305 5.33039 15.9994 5.33334C18.8283 5.33334 21.5414 6.45714 23.5418 8.45753C25.5422 10.4579 26.666 13.171 26.666 16C26.669 18.3689 25.8753 20.6699 24.4127 22.5333Z"
    />
  </svg>
);

const ICON_MAP = {
  success: SuccessIcon,
  error: ErrorIcon,
};

const addToast = (message, { appearance }) => {
  const Icon = ICON_MAP[appearance];
  toast.custom(t => (
    <div
      className={cn(
        t.visible ? "animate-enter" : "animate-leave",
        "pointer-events-auto flex w-full max-w-md rounded-lg bg-white",
        {
          "bg-[#21B881]": appearance === "success",
          "bg-[#EA5455]": appearance === "error",
        }
      )}
    >
      <div className="w-0 flex-1 p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0 pt-0.5 text-white">
            <Icon />
          </div>
          <div className="ml-3 flex-1">
            <p className="mt-1 font-poppins text-sm text-white">{message}</p>
          </div>
        </div>
      </div>
      <div className="flex">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="flex w-full items-center justify-center rounded-none rounded-r-lg p-4 text-white"
        >
          <Clear />
        </button>
      </div>
    </div>
  ));
};

export default addToast;
