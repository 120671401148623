import { cleanSearchParams } from "@/common/utils/router.utils";
import { CANONICAL_DOMAIN } from "config";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";

const defaultImage = "https://www.whoppah.com/icons/icon-512x512.png";

const getUrl = path => {
  const url = new URL(path, `https://${CANONICAL_DOMAIN}`);

  cleanSearchParams(url.searchParams);

  return url.href.replace(/\/$/, "");
};

const SEO = props => {
  const { asPath, locale, defaultLocale } = useRouter();
  const { title, description, image, children, ...other } = props;
  const path = locale !== defaultLocale ? `${locale}${asPath}` : asPath;
  return (
    <Head {...other}>
      {title ? (
        <>
          <title>{`${title} | Whoppah`}</title>
          <meta property="og:title" content={`${title} | Whoppah`} />
          <meta name="twitter:title" content={`${title} | Whoppah`} />
        </>
      ) : null}
      {description ? (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      ) : null}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={getUrl(path)} />
      <meta name="image" content={image ?? defaultImage} />
      <meta property="og:image" content={image ?? defaultImage} />
      <meta name="twitter:image" content={image ?? defaultImage} />
      <meta name="twitter:card" content="summary" />
      {children}
    </Head>
  );
};

export default SEO;
